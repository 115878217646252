import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Searchbar from "./Searchbar";
import { RiCloseFill } from "react-icons/ri";

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <nav className="navbar header sticky-top bg-white">
            <div className="container-fluid">
                <div className="logo" onClick={() => navigate("/")}>
                    <svg
                        role="img"
                        className="svg"
                        aria-label=""
                        aria-hidden="false"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 198.96 50.44"
                    >
                        <title>Trulia</title>
                        <path d="M147.71 11a5.51 5.51 0 1 0-5.5-5.51 5.51 5.51 0 0 0 5.5 5.51zm4.99 29.56V14.64h-15.5v9.28h6.22v25.92h15.49v-9.28h-6.21zm-26.49 0V2.54h-15.49v9.28h6.21v28.74h-6.21v9.28h21.7v-9.28h-6.21zM60.25 14.52h-2.81a12.17 12.17 0 0 0-10.75 5.39l-1.89-5.27h-9.11v9.28H40v25.92h9.28V31.72a8.16 8.16 0 0 1 8.16-8.16h2.81zm42.98 18.35V14.64H94V33.2a8.29 8.29 0 0 1-16.58 0V14.64h-9.33v18.23a17.57 17.57 0 0 0 35.14 0zM27.6 23.92v-9.28H15.49V3.28H6.22v11.36H0v9.28h6.22v8.92a17.56 17.56 0 0 0 17.56 17.57A17.74 17.74 0 0 0 27.6 50v-9.17a9.28 9.28 0 0 1-3.18.56 8.78 8.78 0 0 1-8.93-8.61v-8.86zm162.09-9.28v3.5a13.08 13.08 0 0 0-9.14-4h-.72c-9.17 0-16.6 8.12-16.6 18.15s7.43 18.19 16.6 18.15h.72a13.08 13.08 0 0 0 9.14-4v3.5H199v-35.3zm-8.81 26.82a9.06 9.06 0 0 1-8.88-9.23 8.85 8.85 0 1 1 17.69 0 9 9 0 0 1-8.81 9.23z"></path>
                    </svg>
                </div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-row main-nav">
                    {location.pathname !== "/" && (
                        <li className="nav-item">
                            <div className="header-searchbar">
                                <Searchbar />
                            </div>
                        </li>
                    )}

                    <li className="nav-item custom-drop d-flex mx-0">
                        <a
                            className="nav-link active"
                            aria-current="page"
                            onClick={() => navigate("property-search")}
                        >
                            Buy
                        </a>
                        <div class="dropdown-content mt-4 shadow rounded-2">
                            <h6>Atlanta</h6>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Homes for Sale
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Open Houses
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                New Homes
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Recently Sold
                            </p>
                        </div>
                    </li>
                    <li className="nav-item custom-drop d-flex mx-0">
                        <a className="nav-link" onClick={() => navigate("property-search")}>
                            Rent
                        </a>
                        <div class="dropdown-content mt-4 shadow rounded-2">
                            <h6>Atlanta</h6>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                All Rentals
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Apartments for Rent
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Houses for Rent
                            </p>
                            <hr />
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Post A Rental Listing
                            </p>
                        </div>
                    </li>
                    <li class="nav-item custom-drop d-flex mx-0">
                        <a className="nav-link" onClick={() => navigate("property-search")}>
                            Mortgage
                        </a>
                        <div class="dropdown-content mt-4 shadow rounded-2">
                            <h6>Atlanta</h6>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Mortgage Overview
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Get Pre-Qualified
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Mortgage Rates
                            </p>
                            <p className="mb-0" onClick={() => navigate("property-search")}>
                                Refinance Rates
                            </p>
                        </div>
                    </li>
                </ul>
                <div className="d-flex flex-row main-nav" role="search">
                    <ul className="navbar-nav d-flex flex-row">
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Saved Homes
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Saved Searches
                            </a>
                        </li>
                    </ul>
                    <button className="btn btn-outline-success nav-btn mx-4" type="submit">
                        Sign up or Log in
                    </button>
                </div>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                >
                    <div className="offcanvas-header">
                        <h6 className="offcanvas-title" id="offcanvasNavbarLabel">
                            Sign up or Log in
                        </h6>
                        <button
                            type="button"
                            className="canvas-close-btn"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        >
                            <RiCloseFill />
                        </button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item dropdown mb-3">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Buy
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <p className="offcanvas-para">Atlanta</p>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Homes for Sale</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Open Houses</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">New Homes</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Recently Sold</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown mb-3">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Rent
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <p className="offcanvas-para">Atlanta</p>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">All Rentals</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Apartments for Rent</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Houses for Rent</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown mb-3">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Mortgage
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <p className="offcanvas-para">Atlanta</p>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Mortgage Overview</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Get Pre-Qualified</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Mortgage Rates</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown mb-3">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Local Info
                                </a>
                                <ul className="dropdown-menu">
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">
                                            All Trulia Neighborhood Guides
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Additional Resources
                                </a>
                                <ul className="dropdown-menu">
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Trulia Blogs</a>
                                    </li>
                                    <li onClick={() => navigate("property-search")}>
                                        <a className="dropdown-item">Help Center</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

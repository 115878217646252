import "./App.css";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import PropertySearchPage from "./pages/PropertySearchPage";
import SingleProperty from "./pages/SingleProperty";
import { Routes, Route } from "react-router-dom";

function App() {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="property-search" element={<PropertySearchPage />} />
                <Route path="single-property" element={<SingleProperty />} />
            </Routes>

            {/*  */}
        </>
    );
}

export default App;

import React from "react";
import House1 from "../assets/images/house1.webp";
import House2 from "../assets/images/house2.webp";
import House3 from "../assets/images/house3.webp";
import House4 from "../assets/images/house4.webp";
import House5 from "../assets/images/house5.webp";
import Bed from "../assets/images/bed.svg";
import Bath from "../assets/images/bath.svg";
import SquareFeet from "../assets/images/squarefeet.svg";
import { useNavigate } from "react-router-dom";

export default function PropertySearchPage() {
    const navigate = useNavigate();

    return (
        <>
            <div className="container-fluid">
                <div className="row mb-4 mt-3">
                    <div className="col-lg-12">
                        <div className="filters d-flex">
                            <div className="price-dropdown mb-2">
                                <div class="dropdown">
                                    <button
                                        class="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Any Price
                                    </button>
                                    <div className="dropdown-menu shadow">
                                        <p className="filter-dropdown-title mb-1">Price Range</p>
                                        <div className="price-range-wrapper d-flex align-items-center justify-content-between">
                                            <select
                                                class="form-select"
                                                aria-label="Default select example"
                                            >
                                                <option selected>No Min</option>
                                                <option value="1">$400</option>
                                                <option value="2">$500</option>
                                                <option value="3">$600</option>
                                                <option value="3">$700</option>
                                                <option value="3">$800</option>
                                                <option value="3">$900</option>
                                                <option value="3">$1,000</option>
                                            </select>
                                            <span>-</span>
                                            <select
                                                class="form-select"
                                                aria-label="Default select example"
                                            >
                                                <option selected>No Max</option>
                                                <option value="1">$400</option>
                                                <option value="2">$500</option>
                                                <option value="3">$600</option>
                                                <option value="3">$700</option>
                                                <option value="3">$800</option>
                                                <option value="3">$900</option>
                                                <option value="3">$1,000</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pets-dropdown mb-2">
                                <div class="dropdown">
                                    <button
                                        class="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Pets
                                    </button>
                                    <div className="dropdown-menu px-2 shadow">
                                        <p className="filter-dropdown-title mb-1">Pets</p>
                                        <div className="pets-filter">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexCheckDefault"
                                                >
                                                    Cats Allowed
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckChecked"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexCheckChecked"
                                                >
                                                    Dogs Allowed
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rental-dropdown mb-2">
                                <div class="dropdown">
                                    <button
                                        class="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        All Rental Types
                                    </button>
                                    <div className="dropdown-menu px-2 shadow">
                                        <p className="filter-dropdown-title mb-1">Type of Rental</p>
                                        <div className="pets-filter">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexCheckDefault"
                                                >
                                                    Apartment/Condo/Loft
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckChecked"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexCheckChecked"
                                                >
                                                    Single Family Home
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckChecked"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexCheckChecked"
                                                >
                                                    Townhome
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="near-transit-dropdown mb-2">
                                <div class="dropdown">
                                    <button
                                        class="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Near Transit
                                    </button>
                                    <div className="dropdown-menu px-2 shadow">
                                        <div className="pets-filter">
                                            <div class="form-check form-switch">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="flexSwitchCheckChecked"
                                                >
                                                    Near ATLANTA-MARTA
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="save-search-btn mb-2">
                                <button className="btn">Save Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-7">
                        <h5 className="mb-3">Atlanta, GA Real Estate & Homes For Sale</h5>
                        <div className="filter-and-results d-flex justify-content-between mb-3">
                            <div className="sort-filter">
                                <div className="dropdown">
                                    <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Sort: New Listings
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                Sort: Just For You
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                Sort: New Listings
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                Sort: New Photos
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                Sort: Bedrooms
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="results">
                                <p>3,289 homes</p>
                            </div>
                        </div>

                        <div className="row search-properties">
                            <div className="col-md-12 col-lg-6 mb-3">
                                <div
                                    className="single-search-property"
                                    onClick={() => navigate("/single-property")}
                                >
                                    <div className="state-img rounded-3">
                                        <img src={House1} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - 14 HRS AGO</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mb-3">
                                <div
                                    className="single-search-property"
                                    onClick={() => navigate("/single-property")}
                                >
                                    <div className="state-img rounded-3">
                                        <img src={House2} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - 14 HRS AGO</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mb-3">
                                <div
                                    className="single-search-property"
                                    onClick={() => navigate("/single-property")}
                                >
                                    <div className="state-img rounded-3">
                                        <img src={House3} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - 14 HRS AGO</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mb-3">
                                <div
                                    className="single-search-property"
                                    onClick={() => navigate("/single-property")}
                                >
                                    <div className="state-img rounded-3">
                                        <img src={House4} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - 14 HRS AGO</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 mb-3">
                                <div
                                    className="single-search-property"
                                    onClick={() => navigate("/single-property")}
                                >
                                    <div className="state-img rounded-3">
                                        <img src={House5} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - 14 HRS AGO</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <div className="search-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212271.59673001373!2d-84.56021569011666!3d33.76728837276175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045d6993098d%3A0x66fede2f990b630b!2sAtlanta%2C%20GA%2C%20USA!5e0!3m2!1sen!2s!4v1676966699382!5m2!1sen!2s"
                                width="100%"
                                height="100%"
                                style={{ border: "0" }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

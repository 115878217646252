import React from "react";
import Slider from "react-slick";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Propery1 from "../assets/images/property1.webp";
import Propery2 from "../assets/images/property2.webp";
import Propery3 from "../assets/images/property3.webp";
import Bed from "../assets/images/bed.svg";
import Bath from "../assets/images/bath.svg";
import SquareFeet from "../assets/images/squarefeet.svg";
import MapImg from "../assets/images/map-location.png";
import Parking from "../assets/images/parking.svg";
import Outdoor from "../assets/images/outdoor.svg";
import AC from "../assets/images/ac.svg";
import HOA from "../assets/images/aoh.svg";
import List from "../assets/images/list.svg";
import House1 from "../assets/images/house1.webp";
import House2 from "../assets/images/house2.webp";
import House3 from "../assets/images/house3.webp";
import House4 from "../assets/images/house4.webp";
import House5 from "../assets/images/house5.webp";
import Forsale from "../assets/images/for-sale.svg";

export default function SingleProperty() {
    const newListing = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,
        arrows: false,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <>
            <div className="container mt-3 mb-2">
                <div className="row">
                    <div className="col-md-4 col-lg-2">
                        <div className="back-to-search">
                            <IoMdArrowRoundBack /> Back to Search
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9">
                        <p className="breadcrumbs mb-0">
                            <span>For Sale</span> <IoIosArrowForward /> AZ <IoIosArrowForward /> Rio
                            Verde <IoIosArrowForward /> 85263 <IoIosArrowForward /> 17544 E Desert
                            Vista Tri
                        </p>
                    </div>
                </div>
            </div>

            <section className="single-property-grid-section mb-5">
                <div className="container">
                    <div className="row single-property-row overflow-hidden rounded-2 mb-4">
                        <div className="col-12 col-md-8 col-lg-8 px-md-0 mb-3 mb-md-0">
                            <img src={Propery1} alt="" className="img-fluid" />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 pe-md-0">
                            <div className="img1 property-img mb-2 mb-3 mb-md-0">
                                <img src={Propery2} alt="" className="img-fluid w-100" />
                            </div>
                            <div className="img2 property-img mt-2">
                                <img src={Propery3} alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-8 mb-3 mb-lg-0">
                            <div className="row">
                                <div className="col-lg-12 mb-5">
                                    <div className="single-property-detail d-flex justify-content-between">
                                        <div className="detail-left">
                                            <h4 className="mb-0">17544 E Desert Vista Trl</h4>
                                            <p>Rio Verde, AZ 85263</p>
                                            <div className="d-flex state-details">
                                                <div className="d-flex align-items-center me-2">
                                                    <img src={Bed} alt="" className="me-2" />
                                                    <span>4bd</span>
                                                </div>
                                                <div className="d-flex align-items-center me-2">
                                                    <img src={Bath} alt="" className="me-2" />
                                                    <span>3ba</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <img src={SquareFeet} alt="" className="me-2" />
                                                    <span>1,635 sqft (on 0.26 acres)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="detail-right">
                                            <h4 className="mb-0">$1,580,000</h4>
                                            <p>Est. Mortgage $9,403/mo*</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                    <h5>Local Information</h5>
                                </div>
                                <div className="col-lg-12">
                                    <ul
                                        class="nav nav-pills mb-3 single-property-nav"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li class="nav-item" role="presentation">
                                            <button
                                                class="nav-link active"
                                                id="pills-map-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-map"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-map"
                                                aria-selected="true"
                                            >
                                                Map
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button
                                                class="nav-link"
                                                id="pills-schools-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-schools"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-schools"
                                                aria-selected="false"
                                            >
                                                Schools
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button
                                                class="nav-link"
                                                id="pills-shop-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-shop"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-shop"
                                                aria-selected="false"
                                            >
                                                Shop & Eat
                                            </button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div
                                            class="tab-pane fade show active"
                                            id="pills-map"
                                            role="tabpanel"
                                            aria-labelledby="pills-map-tab"
                                            tabindex="0"
                                        >
                                            <img src={MapImg} alt="" className="img-fluid w-100" />
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="pills-schools"
                                            role="tabpanel"
                                            aria-labelledby="pills-schools-tab"
                                            tabindex="0"
                                        >
                                            <img src={MapImg} alt="" className="img-fluid w-100" />
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="pills-shop"
                                            role="tabpanel"
                                            aria-labelledby="pills-shop-tab"
                                            tabindex="0"
                                        >
                                            <img src={MapImg} alt="" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="schedule-tour-wrap text-center">
                                <p className="mb-1">Request a tour as early as</p>
                                <h6 className="mb-3">Today at 11:00AM</h6>
                                <div>
                                    <button className="schedule-btn">Schedule a Tour</button>
                                </div>
                                <div>
                                    <button className="request-info">Request Info</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="single-local-information mb-5">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-8">
                            <h5 className="mb-3">Local Information</h5>
                            <p>
                                Stunning New Listing-Backs to Acres of Reserve and the 9th hole of
                                the Verde River Golf and Social club golf course. An entertainers
                                delight featuring an extended patio with outdoor kitchen, sparkling
                                pool with gorgeous Mountain views. House features a gourmet kitchen
                                with Monogram appliances, large granite counter tops. Separate
                                dining room with fantastic views. Fireplace in great room, soothing
                                interior colors, tile entry way that blends into custom engineered
                                wood flooring in Kitchen, Dining room, great room and primary en
                                suite. Custom designer lighting and window treatments throughout.
                                House also features an oversized lot, extended garage and epoxy
                                flooring. Remote pool equipment with electric heater which provides
                                both heating and cooling. Easy walk to club house and all amities,
                                Restaurants , Pool, spa, Pickle ball, tennis, and much more. Trilogy
                                at Verde River is not impacted by the hauled water issue in the Rio
                                Verde Foothills.
                            </p>
                        </div>
                    </div>
                    <div className="row highlight-row">
                        <div className="col-12">
                            <h5 className="mb-3">Home Highlights</h5>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="highlight-list d-flex align-items-center mb-2">
                                <img src={Parking} alt="" className="me-2" />
                                <p className="mb-0">Parking</p>
                            </div>
                            <div className="highlight-list d-flex align-items-center mb-2">
                                <img src={Outdoor} alt="" className="me-2" />
                                <p className="mb-0">Outdoor</p>
                            </div>
                            <div className="highlight-list d-flex align-items-center">
                                <img src={AC} alt="" className="me-2" />
                                <p className="mb-0">A/C</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="garage mb-2">
                                <p className="mb-0">Garage</p>
                            </div>
                            <div className="garage mb-2">
                                <p className="mb-0">Patio, Pool</p>
                            </div>
                            <div className="garage">
                                <p className="mb-0">Heating & Cooling</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="highlight-list d-flex align-items-center mb-2">
                                <img src={HOA} alt="" className="me-2" />
                                <p className="mb-0">HOA</p>
                            </div>
                            <div className="highlight-list d-flex align-items-center mb-2">
                                <img src={SquareFeet} alt="" className="me-2" />
                                <p className="mb-0">Price/Sqft</p>
                            </div>
                            <div className="highlight-list d-flex align-items-center">
                                <img src={List} alt="" className="me-2" />
                                <p className="mb-0">Listed</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="col-lg-3">
                                <div className="garage mb-2">
                                    <p className="mb-0">$444/Monthly</p>
                                </div>
                                <div className="garage mb-2">
                                    <p className="mb-0">$723</p>
                                </div>
                                <div className="garage">
                                    <p className="mb-0">No Info</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="single-local-information">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <p className="mb-2">Last check for updates: about 12 hours ago</p>
                            <p className="mb-2">
                                Listing courtesy of James E. Lillibridge, (480) 437-9100
                            </p>
                            <p className="mb-2">Sonoran Properties Associates</p>
                            <p className="mb-2">
                                All information should be verified by the recipient and none is
                                guaranteed as accurate by ARMLS
                            </p>
                            <p className="mb-2">
                                Listing Information presented by local MLS brokerage: Zillow, Inc.,
                                local REALTOR®- Chris Long - (480) 907-1010
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h5 className="mb-3">Price History for 17544 E Desert Vista Trl</h5>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <table class="table price-history-table">
                                <thead>
                                    <tr className="table-active">
                                        <th scope="col">Date</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Event</th>
                                        <th scope="col">Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">02/20/2023</th>
                                        <td>$1,580,000</td>
                                        <td>Listed For Sale</td>
                                        <td>ARMLS #6522500</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">07/12/2021</th>
                                        <td>$1,300,000</td>
                                        <td>Sold</td>
                                        <td>N/A</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">06/06/2021</th>
                                        <td>$1,268,000</td>
                                        <td>Pending</td>
                                        <td>ARMLS #6240701</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <h5>New Listings near 17544 E Desert Vista Trl</h5>
                        </div>
                        <div className="col-lg-12 newly-listed px-lg-0">
                            <Slider {...newListing}>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House1} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - 14 HRS AGO</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House2} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House3} alt="" className="img-fluid" />
                                    </div>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House4} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">comming soon</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House5} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - 1 day ago</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House1} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House2} alt="" className="img-fluid" />
                                    </div>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card ps-3 border-0">
                                    <div className="state-img rounded-2">
                                        <img src={House3} alt="" className="img-fluid" />
                                    </div>
                                    <span className="tag">New - Open Mon, 1-3 PM</span>
                                    <div className="card-body ps-0 pt-2">
                                        <h3 className="fs-5 mb-1">$241,542,21</h3>
                                        <div className="d-flex state-details">
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bed} alt="" />
                                                <span>4bd</span>
                                            </div>
                                            <div className="d-flex align-items-center me-1">
                                                <img src={Bath} alt="" />
                                                <span>3ba</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={SquareFeet} alt="" />
                                                <span>1,635 sqft</span>
                                            </div>
                                        </div>
                                        <address className="mb-0 card-address">
                                            334 Date Ave #1, Carlsbad, CA 92008
                                        </address>
                                        <p>
                                            <small className="text-uppercase fs-7">
                                                PACIFIC SOTHEBY'S INT'L REALTY
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <h5 className="mb-3">Property Taxes and Assessment</h5>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <table class="table price-history-table">
                                <tbody>
                                    <tr>
                                        <td scope="row" className="bg-light">
                                            Year
                                        </td>
                                        <td>2022</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" className="bg-light">
                                            Tax
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td scope="row" className="bg-light">
                                            Assessment
                                        </td>
                                        <td>$2,470,800</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <h5 className="mb-1">Price Trends</h5>
                            <p>For homes in 30309</p>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="forsale-wrapper d-flex align-items-start">
                                <img src={Forsale} alt="" />
                                <div className="forsale-content ms-2">
                                    <h6 className="mb-1">$410,883</h6>
                                    <p>
                                        Typical home value <br /> This home: $3,995,000{" "}
                                        <span className="fw-bold">90% above</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="forsale-wrapper d-flex align-items-start">
                                <img src={SquareFeet} alt="" />
                                <div className="forsale-content ms-2">
                                    <h6 className="mb-1">$366</h6>
                                    <p>
                                        Typical Home Value by sqft
                                        <br /> This home: $3,995,000{" "}
                                        <span className="fw-bold">47% above</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <p>Price trends provided by third party data sources.</p>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <h5 className="mb-3">Property Taxes and Assessment</h5>
                            <div className="single-prop-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212271.59673001373!2d-84.56021569011666!3d33.76728837276175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045d6993098d%3A0x66fede2f990b630b!2sAtlanta%2C%20GA%2C%20USA!5e0!3m2!1sen!2s!4v1676966699382!5m2!1sen!2s"
                                    width="100%"
                                    height="400px"
                                    style={{ border: "0" }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="realestate-market">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <h5>Real Estate Markets</h5>
                            <ul>
                                <li>Alaska Real Estate</li>
                                <li>Alabama Real Estate</li>
                                <li>Arkansas Real Estate</li>
                                <li>Arizona Real Estate</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h5>Popular Searches</h5>
                            <ul>
                                <li>Houses for Rent Near Me</li>
                                <li>Pet Friendly Apartments Near Me</li>
                                <li>Open Houses Near Me</li>
                                <li>Townhomes for Rent Near Me</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h5>Explore Trulia</h5>
                            <ul className="social-list">
                                <li className="d-flex">
                                    <svg
                                        role="img"
                                        aria-label=""
                                        aria-hidden="true"
                                        class="svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M13.416 20v-7.313h2.442l.376-2.843h-2.818V8.03c0-.406.086-.737.259-.992.172-.255.555-.383 1.15-.383h1.503v-2.53c-.125-.031-.391-.06-.798-.086A22.06 22.06 0 0 0 14.136 4c-.542 0-1.04.08-1.495.242a3.07 3.07 0 0 0-1.158.719 3.292 3.292 0 0 0-.744 1.172c-.177.463-.266 1.002-.266 1.617v2.094H8v2.844h2.473V20h2.943z"></path>
                                    </svg>
                                    Facebook
                                </li>
                                <li className="d-flex">
                                    <svg
                                        role="img"
                                        aria-label=""
                                        aria-hidden="true"
                                        class="svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <title>Twitter</title>
                                        <path d="M21 6.815c-.653.283-1.203.292-1.786.013.752-.437.787-.745 1.059-1.57a7.44 7.44 0 0 1-2.315.858A3.694 3.694 0 0 0 15.298 5c-2.012 0-3.644 1.584-3.644 3.534 0 .277.032.547.094.806a10.453 10.453 0 0 1-7.511-3.693 3.438 3.438 0 0 0-.494 1.777c0 1.227.644 2.308 1.621 2.942a3.717 3.717 0 0 1-1.65-.442v.044c0 1.713 1.256 3.141 2.924 3.466a3.78 3.78 0 0 1-1.647.062c.465 1.403 1.81 2.425 3.405 2.454A7.494 7.494 0 0 1 3 17.413 10.553 10.553 0 0 0 8.587 19c6.703 0 10.368-5.385 10.368-10.056 0-.154-.002-.306-.01-.457.712-.498 1.567-.962 2.055-1.672z"></path>
                                    </svg>
                                    Twitter
                                </li>
                                <li className="d-flex">
                                    <svg
                                        role="img"
                                        aria-label=""
                                        aria-hidden="true"
                                        class="svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M12 3c2.444 0 2.75.01 3.71.054.959.044 1.613.196 2.185.419.592.23 1.094.537 1.594 1.038.5.5.809 1.002 1.039 1.594.222.572.374 1.226.418 2.184C20.99 9.25 21 9.556 21 12s-.01 2.75-.054 3.71c-.044.959-.196 1.613-.418 2.185a4.412 4.412 0 0 1-1.039 1.594c-.5.5-1.002.809-1.594 1.039-.572.222-1.226.374-2.184.418-.96.044-1.267.054-3.711.054s-2.75-.01-3.71-.054c-.959-.044-1.613-.196-2.185-.418a4.412 4.412 0 0 1-1.594-1.039c-.5-.5-.809-1.002-1.038-1.594-.223-.572-.375-1.226-.419-2.184C3.01 14.75 3 14.444 3 12s.01-2.75.054-3.71c.044-.959.196-1.613.419-2.185A4.411 4.411 0 0 1 4.51 4.51c.5-.5 1.002-.809 1.594-1.038.572-.223 1.226-.375 2.184-.419C9.25 3.01 9.556 3 12 3zm0 1.622c-2.403 0-2.688.009-3.637.052-.877.04-1.354.187-1.67.31a2.79 2.79 0 0 0-1.036.673c-.315.315-.51.615-.673 1.035-.123.317-.27.794-.31 1.671-.043.95-.052 1.234-.052 3.637s.009 2.688.052 3.637c.04.877.187 1.354.31 1.67.163.421.358.72.673 1.036.315.315.615.51 1.035.673.317.123.794.27 1.671.31.95.043 1.234.052 3.637.052s2.688-.009 3.637-.052c.877-.04 1.354-.187 1.67-.31a2.79 2.79 0 0 0 1.036-.673c.315-.315.51-.615.673-1.035.123-.317.27-.794.31-1.671.043-.95.052-1.234.052-3.637s-.009-2.688-.052-3.637c-.04-.877-.187-1.354-.31-1.67a2.788 2.788 0 0 0-.673-1.036 2.788 2.788 0 0 0-1.035-.673c-.317-.123-.794-.27-1.671-.31-.95-.043-1.234-.052-3.637-.052zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-7.622a4.622 4.622 0 1 1 0 9.244 4.622 4.622 0 0 1 0-9.244zm5.884-.182a1.08 1.08 0 1 1-2.16 0 1.08 1.08 0 0 1 2.16 0z"></path>
                                    </svg>
                                    Instagram
                                </li>
                                <li className="d-flex">
                                    <svg
                                        role="img"
                                        aria-label=""
                                        aria-hidden="true"
                                        class="svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm1.043 11.671c-.744 0-1.444-.4-1.683-.857 0 0-.401 1.587-.484 1.894-.299 1.082-1.177 2.167-1.245 2.255-.047.063-.152.044-.164-.04-.019-.137-.242-1.503.02-2.618l.888-3.756s-.22-.441-.22-1.091c0-1.021.592-1.784 1.328-1.784.627 0 .93.47.93 1.034 0 .63-.401 1.573-.609 2.446-.173.732.368 1.329 1.088 1.329 1.306 0 2.186-1.679 2.186-3.666 0-1.51-1.017-2.641-2.868-2.641-2.09 0-3.394 1.56-3.394 3.3 0 .373.07.678.187.934l-1.136.604c-.266-.477-.395-1.075-.395-1.709C7.472 8.348 9.124 6 12.398 6c2.633 0 4.364 1.904 4.364 3.95 0 2.702-1.503 4.722-3.719 4.722z"></path>
                                    </svg>
                                    Pinterest
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h5>For Professionals</h5>
                            <ul>
                                <li>Popular Counties</li>
                                <li>Rental Communities</li>
                                <li>Real Estate Leads</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-section mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                                quisquam qui natus consectetur repudiandae quod, illo asperiores ad
                                facilis voluptas fugiat, ipsa non quaerat? At quia error officia
                                suscipit dolor. Commodi, neque assumenda consequuntur id quia quidem
                                doloribus culpa pariatur blanditiis. Tempore assumenda error hic
                                distinctio repudiandae unde nihil dolore? Laborum libero quas
                                maiores esse, magni quam voluptatibus repudiandae dolorem?
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                                quisquam qui natus consectetur repudiandae quod, illo asperiores ad
                                facilis voluptas fugiat, ipsa non quaerat? At quia error officia
                                suscipit dolor. Commodi, neque assumenda consequuntur id quia quidem
                                doloribus culpa pariatur blanditiis. Tempore assumenda error hic
                                distinctio repudiandae unde nihil dolore? Laborum libero quas
                                maiores esse, magni quam voluptatibus repudiandae dolorem?
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                                quisquam qui natus consectetur repudiandae quod, illo asperiores ad
                                facilis voluptas fugiat, ipsa non quaerat? At quia error officia
                                suscipit dolor. Commodi, neque assumenda consequuntur id quia quidem
                                doloribus culpa pariatur blanditiis. Tempore assumenda error hic
                                distinctio repudiandae unde nihil dolore? Laborum libero quas
                                maiores esse, magni quam voluptatibus repudiandae dolorem?
                            </p>
                        </div>
                        {/* <div className="col-12 text-center">
                            <svg
                                aria-hidden="true"
                                style={{ width: "70%", height: "100%", verticalAlign: "bottom" }}
                                width="704"
                                height="113"
                                viewBox="0 0 704 113"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none" fill-rule="evenodd">
                                    <path
                                        fill="#F6E3D5"
                                        d="M687.244 37.378h-23.687L684.07 25.46l-4.289-7.474-20.513 11.917L671.11 9.262l-7.429-4.315-11.843 20.64V1.754h-8.578v23.834L631.418 4.947l-7.43 4.315 11.844 20.642-20.513-11.917-4.289 7.474 20.513 11.917h-23.686v8.63h23.688L611.03 57.927l4.289 7.474 20.513-11.917-11.843 20.642 7.429 4.316 11.843-20.642v23.835h8.578V57.8l11.843 20.64 7.43-4.315-11.844-20.642L679.78 65.4l4.289-7.474-20.515-11.917h23.689z"
                                    ></path>
                                    <path
                                        fill="#144225"
                                        d="M595.426 25.97v24.457L566.86 58.18V113h18.95V89.238h9.308V113h58.771V42.575z"
                                    ></path>
                                    <path
                                        fill="#00ADBB"
                                        d="M566.859 113V76.436l-11.653-10.28-8.513 7.496-8.518-7.492-8.561 7.539-8.523-7.505-11.923 10.518v36.232l6.164.006V79.402l5.763-5.084 5.486 4.831v33.812l6.183.006V79.046l5.413-4.768 5.487 4.827v33.872l6.183.007V78.996l5.355-4.715 5.523 4.872v33.841z"
                                    ></path>
                                    <path
                                        d="M689.618 64.607c-7.502 0-13.584 6.095-13.584 13.613l.015 8.97a13.603 13.603 0 0 0 10.486 12.918V113h6.167v-12.892a13.603 13.603 0 0 0 10.486-12.918l.014-8.97c0-7.518-6.081-13.613-13.584-13.613zm-46.23 2.19l.015 20.393a13.602 13.602 0 0 0 10.485 12.917V113h6.167v-12.893A13.602 13.602 0 0 0 670.54 87.19h.015V66.798c0-7.518-6.082-13.613-13.584-13.613-7.501 0-13.583 6.095-13.583 13.613z"
                                        fill="#C3D600"
                                    ></path>
                                    <g>
                                        <path
                                            d="M334.785 37.712h42.454V113H265.062V25.278h28.142V.823h41.581v36.89zm-53.191 14.032h12.003V38.553h-12.003v13.191zm12.003 27.668h12.004v-13.19h-12.004v13.19z"
                                            fill="#CEB7FF"
                                        ></path>
                                        <path
                                            d="M234.254 65.543c7.077 0 12.814 5.977 12.814 13.35l-.014 8.796c-.146 6.181-4.317 11.32-9.892 12.668V113h-5.816v-12.643c-5.575-1.349-9.745-6.487-9.891-12.668l-.014-8.797c0-7.372 5.736-13.35 12.813-13.35zm44.135 2.736l-.014 19.738c-.146 6.101-4.316 11.173-9.89 12.504V113h-5.817v-12.48c-5.575-1.33-9.746-6.402-9.892-12.503h-.014V68.28c0-7.278 5.737-13.177 12.814-13.177 7.076 0 12.813 5.9 12.813 13.177z"
                                            fill="#144225"
                                        ></path>
                                        <path
                                            d="M401.33 31.69L360.677 68.29V113h81.31V68.289L401.33 31.69zm-24.091 65.5h8.281V80.627h-8.281V97.19z"
                                            fill="#730731"
                                        ></path>
                                        <path
                                            d="M458.343 78.564h35.48v18.783h-35.48V78.564zm-5.317-5.156V113h-5.118V68.13h44.87v5.278h-39.752z"
                                            fill="#C3D600"
                                        ></path>
                                    </g>
                                    <g>
                                        <path
                                            d="M54.142 75.066c23.159 0 42.611 16.21 47.76 37.934h-8.08c-4.956-17.342-20.862-30.072-39.68-30.072-.696 0-1.388.018-2.075.053v-7.863c.689-.028 1.38-.052 2.075-.052zm-2.075 23.146c.687-.053 1.38-.088 2.08-.088 10.55 0 19.679 6.087 23.953 14.876h-9.158a18.782 18.782 0 0 0-14.794-7.165c-.703 0-1.397.042-2.081.117v-7.74zM116.86 113c-5.373-29.703-31.454-52.306-62.723-52.306-.693 0-1.383.015-2.071.038v-7.799A71.244 71.244 0 0 1 80.9 58.091c12.093-9.718 27.404-15.588 44.06-15.753v7.791a63.44 63.44 0 0 0-36.15 11.743l-.003-.002a71.907 71.907 0 0 1 12.946 9.203 48.733 48.733 0 0 1 23.207-6.24v7.802a40.99 40.99 0 0 0-17.44 4.201 71.81 71.81 0 0 1 8.826 12.257 26.298 26.298 0 0 1 8.614-1.69v7.806c-1.775.069-3.49.375-5.109.903A70.831 70.831 0 0 1 124.775 113h-7.914z"
                                            fill="#CEB7FF"
                                        ></path>
                                        <path
                                            d="M136.22 41.706h-9.72V31.012h9.72v10.694zm9.722 23.17h-9.721V54.184h9.721v10.694zm18.152-46.34v27.407l22.571-14.034 23.79 14.792-.114.116h.1V113h-93.662V18.536h47.315z"
                                            fill="#FF8C68"
                                        ></path>
                                        <path
                                            d="M.974 79.955A14.766 14.766 0 0 0 0 85.246c0 6.88 4.694 12.664 11.06 14.337v13.28h7.582v-13.28c6.365-1.672 11.06-7.457 11.06-14.337 0-1.638-.266-3.213-.758-4.686L24.91 67.623A10.441 10.441 0 0 0 14.85 60a10.442 10.442 0 0 0-10.044 7.573L.974 79.955zm33.654 1.827a14.85 14.85 0 0 0-.362 3.265c0 6.88 4.695 12.664 11.06 14.337v13.48h7.583v-13.48c6.365-1.673 11.059-7.458 11.059-14.337 0-.946-.089-1.872-.259-2.77h.115l-4.332-22.644c-.584-5.204-5.006-9.25-10.375-9.25s-9.791 4.046-10.374 9.25l-4.115 22.149z"
                                            fill="#730731"
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

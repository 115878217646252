import React from "react";

export default function Searchbar() {
    return (
        <div className="search d-flex mt-4">
            <input type="text" placeholder="Search for city" />
            <div className="search-button">
                <div className="search-icon">
                    <svg
                        role="img"
                        aria-label=""
                        aria-hidden="true"
                        className="svg"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.756 18.876l6.155 6.154-1.88 1.881-6.155-6.155A9.269 9.269 0 0 1 13.3 22.61a9.31 9.31 0 1 1 9.31-9.31c0 2.091-.69 4.021-1.854 5.576zM13.3 19.95a6.65 6.65 0 1 0 0-13.3 6.65 6.65 0 0 0 0 13.3z"
                            fill="#fff"
                        ></path>
                    </svg>
                </div>
            </div>
        </div>
    );
}
